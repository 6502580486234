import classNames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import { InternalExternalLink } from "../../Common/InternalExternalLink";
import { useSiteContext } from "../../SiteContext";

import * as styles from "./Menu.module.scss";
import { useMeta } from "../../../hooks/useMeta";

const Menu = ({ className }) => {
  const { menuOpen, setMenuOpen } = useSiteContext();
  const { isSingleton, parentPrefix } = useMeta();
  const [transition, setTransition] = useState(false);

  const { routes } = useStaticQuery(graphql`
    query MenuQuery {
      routes: allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "route-page" } } }
        sort: { frontmatter: { order: ASC } }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title: short_title
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    setTransition(true);
  }, []);

  return (
    <>
      <aside
        className={classNames(
          styles.menu,
          { [styles.open]: menuOpen, [styles.transition]: transition },
          className
        )}
        aria-hidden={!menuOpen}
      >
        <div className={styles.top}>
          <button
            onClick={() => setMenuOpen(false)}
            className={classNames("btn-reset", "text-button")}
            aria-label="Close Menu Button"
            tabIndex={menuOpen ? 0 : -1}
          >
            Close
          </button>
        </div>
        <div className={styles.upper}>
          <h3 className={styles.sectionTitle}>Routes</h3>

          <ul className={styles.linkList}>
            {routes.edges.map(({ node }) => (
              <li key={node.fields.slug}>
                <InternalExternalLink
                  onClick={() => setMenuOpen(false)}
                  to={isSingleton ? "/" : node.fields.slug}
                  tabIndex={menuOpen ? 0 : -1}
                >
                  {node.frontmatter.title}
                </InternalExternalLink>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.lower}>
          <h3 className={styles.sectionTitle}>Information</h3>

          <ul className={styles.linkList}>
            <li>
              <InternalExternalLink
                tabIndex={menuOpen ? 0 : -1}
                onClick={() => setMenuOpen(false)}
                to="/about"
              >
                About
              </InternalExternalLink>
            </li>
            <li>
              <InternalExternalLink
                tabIndex={menuOpen ? 0 : -1}
                onClick={() => setMenuOpen(false)}
                to="/directory"
              >
                Directory
              </InternalExternalLink>
            </li>
            <li>
              <InternalExternalLink tabIndex={menuOpen ? 0 : -1} to="/contact">
                Contact
              </InternalExternalLink>
            </li>
            <li>
              <InternalExternalLink
                tabIndex={menuOpen ? 0 : -1}
                to="https://manchester.beermil.es"
              >
                Manchester
              </InternalExternalLink>
            </li>
          </ul>
          <h3 className={styles.sectionTitle}>Links</h3>

          <ul className={styles.linkList}>
            <li>
              <InternalExternalLink
                className={styles.small}
                tabIndex={menuOpen ? 0 : -1}
                onClick={() => setMenuOpen(false)}
                to="https://www.instagram.com/beermileslondon/"
              >
                Instagram
              </InternalExternalLink>
            </li>
            <li>
              <InternalExternalLink
                className={styles.small}
                tabIndex={menuOpen ? 0 : -1}
                onClick={() => setMenuOpen(false)}
                to="https://twitter.com/BeerMilesLondon"
              >
                Twitter
              </InternalExternalLink>
            </li>
          </ul>

          <span className={styles.sub}>
            Made by{" "}
            <InternalExternalLink
              tabIndex={menuOpen ? 0 : -1}
              to="https://jthaw.me"
            >
              jthaw.me
            </InternalExternalLink>
          </span>
        </div>
      </aside>
      <div
        role="button"
        tabIndex={menuOpen ? 0 : -1}
        aria-label="Hide Menu"
        onClick={() => setMenuOpen(false)}
        onKeyUp={(e) => e.key === "Enter" && setMenuOpen(false)}
        className={classNames(styles.overlay, { [styles.show]: menuOpen })}
      />
    </>
  );
};

export { Menu };
